.App {
  padding-top: 20px;
  padding-bottom: 20px;
  max-width: 960px;
  margin: 0 auto;
}

.App__header {
  padding-left: 20px;
  padding-right: 20px;
}

.App__main {
  margin-top: 20px;
}
