@import url(https://fonts.googleapis.com/css?family=Lato:200, 400);
@import url(https://fonts.googleapis.com/css?family=Lustria);
.Header {
  height: 40px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: normal;
          align-items: normal;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.Header h1 {
  margin: 0;
}

.Header a {
  color: #444;
  text-decoration: none;
}

.Header__tagline--wide {
  opacity: 0.35;
  margin-left: 10px;
}

.Header__tagline--narrow {
  opacity: 0.35;
  display: block;
  text-align: center;
  display: none;
  margin-top: 10px;
}

.Header__not-logged-in {
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 1 1;
          flex: 1 1;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}

.Header__not-logged-in a:last-child {
  background: rgb(255, 121, 121);
  color: white;
  padding: 5px 15px;
  border-radius: 15px;
  margin-left: 10px;
}

.Header__logged-in a {
  border: 1px solid rgba(0, 0, 0, 0.25);
  padding: 5px 15px;
  border-radius: 15px;
}

@media screen and (min-width: 450px) {
  .Header {
    -webkit-align-items: baseline;
            align-items: baseline;
  }

  .Header h1 {
    font-size: 22pt;
  }
}

@media screen and (max-width: 490px) {
  .Header__tagline--wide {
    display: none;
  }

  .Header__tagline--narrow {
    display: inherit;
  }
}


.Hyph {
  margin-right: 5px;
  margin-left: 5px;
}

.Input,
.Textarea {
  border: 1px solid #ddd;
  display: block;
  font-size: 1.1em;
  margin-bottom: 10px;
  padding: 10px;
  text-align: left;
  width: 100%;
  border-radius: 4px;
}

.Input::-webkit-input-placeholder,
.Textarea::-webkit-input-placeholder {
  color: #ccc;
}

.Input::-ms-input-placeholder,
.Textarea::-ms-input-placeholder {
  color: #ccc;
}

.Input::placeholder,
.Textarea::placeholder {
  color: #ccc;
}

.Textarea {
  resize: vertical;
}

.Button {
  -webkit-order: 1px solid #aaa;
          order: 1px solid #aaa;
  background-color: #eee;
  font-size: 1.1em;
  border-radius: 4px;
  padding: 8px 12px;
  font-weight: bold;
}

.Required {
  color: #CA1551;
  font-family: Arial;
  font-weight: bold;
}

.Section {
  margin-left: 20px;
  margin-right: 20px;
}
.Section--list {
  margin-left: 10px;
  margin-right: 10px;
}

.ThingListItem {
  background: none;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  border: 0;
  font-size: smaller;
  text-decoration: none;
  box-shadow: 0px 11px 21px -8px rgba(0, 0, 0, 0.5);
  margin-bottom: 40px;
  margin-right: 20px;
  border-radius: 10px;
}

.ThingListItem:hover {
  cursor: pointer;
}

.ThingListItem,
.ThingListItem:visited {
  color: #555;
}

.ThingListItem__image {
  height: 250px;
  width: 275px;
  border-radius: 10px 10px 0 0;
  background-size: cover;
  background-position: 50% 50%;
}

.ThingListItem__details {
  padding: 10px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 1 1;
          flex: 1 1;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  width: 275px;
}

.ThingListItem__details h2 {
  margin: 0;
}

.ThingListItem__details .ThingStarRating {
  padding: 5px 0;
  text-align: center;
  opacity: 0.85;
}

.ThingListItem__details span {
  display: block;
  text-align: center;
  opacity: 0.5;
}

.ThingListPage {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.ReviewForm select, .ReviewForm label {
  font-size: 1.1em;
}

.ReviewForm label {
  display: inline-block;
  margin: 10px 10px 20px;
  margin-left: 0;
}

.ThingPage__image {
  height: 20vw;
  min-height: 150px;
  background-size: cover;
  background-position: 50% 50%;
  border-radius: 10px 10px 0 0;
}

.ThingPage__review-list {
  /* padding-inline-start: 20px; */
  list-style-type: none;
  padding: 0;
}

.ThingPage__content {
  margin-bottom: 30px;
}

.ThingPage__review {
  position: relative;
}

.ThingPage__review::before {
  border-color: transparent #f5f5f5;
  border-style: solid;
  border-width: 0px 0px 10px 10px;
  content: '';
  display: block;
  height: 0px;
  position: absolute;
  width: 0;
  bottom: 14px;
  right: 7px;
}

.ThingPage__review-icon {
  font-size: 12pt;
  margin-right: 10px;
  vertical-align: super !important;
}

.ThingPage__review-text {
  background: #f5f5f5;
  padding: 8px;
  margin-bottom: 4px;
  border-radius: 4px;
}

.ThingPage__review-user {
  color: #666;
  margin: 8px 0 0;
  font-size: 10pt;
  font-weight: bold;
  text-align: right;
}

.ThingPage__review-user .ThingStarRating {
  display: inline;
}

.App {
  padding-top: 20px;
  padding-bottom: 20px;
  max-width: 960px;
  margin: 0 auto;
}

.App__header {
  padding-left: 20px;
  padding-right: 20px;
}

.App__main {
  margin-top: 20px;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Lato", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Lustria";
  font-weight: 100;
  color: #222;
}

p {
  color: #555;
}

.blue {
  color: #477ACC;
}

.yellow {
  color: #EAC435;
}

.green {
  color: #09a55e;
}

.red {
  color: #CA1551;
}

.orange {
  color: #FB4D3D;
}

