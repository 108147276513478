@import url('https://fonts.googleapis.com/css?family=Lato:200, 400');
@import url('https://fonts.googleapis.com/css?family=Lustria');

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Lato", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Lustria";
  font-weight: 100;
  color: #222;
}

p {
  color: #555;
}

.blue {
  color: #477ACC;
}

.yellow {
  color: #EAC435;
}

.green {
  color: #09a55e;
}

.red {
  color: #CA1551;
}

.orange {
  color: #FB4D3D;
}
